
export const state = () => ({
    GameReportList: [],
    DepositReportList: [],
    WithdrawReportList: [],
    PromotionReportList: [],
    TransferReportList: [],
    CommissionReportList: [],
    ConversionReportList: [],

    GameTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 10 } },
    DepositTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 10 } },
    WithdrawTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 10 } },
    PromotionTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 10 } },
    TransferTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 10 } },
    CommissionTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 10 } },
    ConversionTableSetting: { Loading: true, PageSetting: { Total: 1, Current: 1, PageSize: 10 } },

    SelectDate: [
        { itemTag: "store.today", itemKey: "Today" },
        { itemTag: "store.yesterday", itemKey: "Yesterday" },
        { itemTag: "store.lastweek", itemKey: "LastWeek" },
        { itemTag: "store.thisweek", itemKey: "ThisWeek" },
        { itemTag: "store.lastmonth", itemKey: "LastMonth" },
        { itemTag: "store.thismonth", itemKey: "ThisMonth" }
    ],
})

export const getters = {

}

export const mutations = {
    setGameReportList: (state, data) => {
        state.GameReportList = data.list
        state.GameTableSetting.PageSetting.Total = data.total
    },
    setDepositReportList: (state, data) => {
        state.DepositReportList = data.list
        state.DepositTableSetting.PageSetting.Total = data.total
    },
    setWithdrawReportList: (state, data) => {
        state.WithdrawReportList = data.list
        state.WithdrawTableSetting.PageSetting.Total = data.total
    },
    setPromotionReportList: (state, data) => {
        state.PromotionReportList = data.list
        state.PromotionTableSetting.PageSetting.Total = data.total
    },
    setTransferReportList: (state, data) => {
        state.TransferReportList = data.list
        state.TransferTableSetting.PageSetting.Total = data.total
    },
    setCommissionReportList: (state, data) => {
        state.CommissionReportList = data.list
        state.CommissionTableSetting.PageSetting.Total = data.total
    },
    setConversionReportList: (state, data) => {
        state.ConversionReportList = data.list
        state.ConversionTableSetting.PageSetting.Total = data.total
    },
    setSelectTime: (state, data) => {
        switch (data.type) {
            case "Game":
                state.GameReportSelect.StartTime = data.stDate
                state.GameReportSelect.EndTime   = data.edDate
                break;
            case "Deposit":
                state.DepositReportSelect.StartTime = data.stDate
                state.DepositReportSelect.EndTime   = data.edDate
                break;
            case "Withdraw":
                state.WithdrawReportSelect.StartTime = data.stDate
                state.WithdrawReportSelect.EndTime   = data.edDate
                break;
            case "Promotion":
                state.PromotionReportSelect.StartTime = data.stDate
                state.PromotionReportSelect.EndTime   = data.edDate
                break;
            case "Transfer":
                state.TransferReportSelect.StartTime = data.stDate
                state.TransferReportSelect.EndTime   = data.edDate
                break;
            case "Commission":
                state.CommissionReportSelect.StartTime = data.stDate
                state.CommissionReportSelect.EndTime   = data.edDate
                break;
            case "Conversion":
                state.ConversionReportSelect.StartTime = data.stDate
                state.ConversionReportSelect.EndTime   = data.edDate
                break;
        }
    },
    setTableLoading: (state, data) => {
        switch (data.type) {
            case "Game":
                state.GameTableSetting.Loading = data.value
                break;
            case "Deposit":
                state.DepositTableSetting.Loading = data.value
                break;
            case "Withdraw":
                state.WithdrawTableSetting.Loading = data.value
                break;
            case "Promotion":
                state.PromotionTableSetting.Loading = data.value
                break;
            case "Transfer":
                state.TransferTableSetting.Loading = data.value
                break;
            case "Commission":
                state.CommissionTableSetting.Loading = data.value
                break;
            case "Conversion":
                state.ConversionTableSetting.Loading = data.value
                break;
        }
    },
    setTableCurrent: (state, data) => {
        switch (data.type) {
            case "Game":
                state.GameTableSetting.PageSetting.Current = data.value
                break;
            case "Deposit":
                state.DepositTableSetting.PageSetting.Current = data.value
                break;
            case "Withdraw":
                state.WithdrawTableSetting.PageSetting.Current = data.value
                break;
            case "Promotion":
                state.PromotionTableSetting.PageSetting.Current = data.value
                break;
            case "Transfer":
                state.TransferTableSetting.PageSetting.Current = data.value
                break;
            case "Commission":
                state.CommissionTableSetting.PageSetting.Current = data.value
                break;
            case "Conversion":
                state.ConversionTableSetting.PageSetting.Current = data.value
                break;
        }
    },
}

export const actions = {
    async getGameReportList({ state, dispatch, commit }, data) {
        commit('setTableLoading', { type: 'Game', value: true});
        let SelectForm  = data.SelectForm
        let PageSetting = state.GameTableSetting.PageSetting

        commit('setGameReportList', {list: [], total: state.GameTableSetting.PageSetting.Total });
        const response = await this.$axios.post('/api/Report/GameList', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setGameReportList', response.data);
            commit('setTableLoading', { type: 'Game', value: false});
            return res
        })
    },
    async getDepositReportList({ state, dispatch, commit }, data) {
        commit('setTableLoading', { type: 'Deposit', value: true});
        let SelectForm  = data.SelectForm
        let PageSetting = state.DepositTableSetting.PageSetting

        const response = await this.$axios.post('/api/Report/DepositList', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setDepositReportList', response.data);
            commit('setTableLoading', { type: 'Deposit', value: false});
            return res
        })
    },
    async getWithdrawReportList({ state, dispatch, commit }, data) {
        commit('setTableLoading', { type: 'Withdraw', value: true});
        let SelectForm  = data.SelectForm
        let PageSetting = state.WithdrawTableSetting.PageSetting

        const response = await this.$axios.post('/api/Report/WithdrawList', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setWithdrawReportList', response.data);
            commit('setTableLoading', { type: 'Withdraw', value: false});
            return res
        })
    },
    async getPromotionReportList({ state, dispatch, commit }, data) {
        commit('setTableLoading', { type: 'Promotion', value: true});
        let SelectForm  = data.SelectForm
        let PageSetting = state.PromotionTableSetting.PageSetting

        const response = await this.$axios.post('/api/Report/PromotionList', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setPromotionReportList', response.data);
            commit('setTableLoading', { type: 'Promotion', value: false});
            return res
        })
    },
    async getTransferReportList({ state, dispatch, commit }, data) {
        commit('setTableLoading', { type: 'Transfer', value: true});
        let SelectForm  = data.SelectForm
        let PageSetting = state.TransferTableSetting.PageSetting

        const response = await this.$axios.post('/api/Report/TransferList', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setTransferReportList', response.data);
            commit('setTableLoading', { type: 'Transfer', value: false});
            return res
        })
    },
    async getCommissionReportList({ state, dispatch, commit }, data) {
        commit('setTableLoading', { type: 'Commission', value: true});
        let SelectForm  = data.SelectForm
        let PageSetting = state.CommissionTableSetting.PageSetting

        const response = await this.$axios.post('/api/Report/CommissionList', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setCommissionReportList', response.data);
            commit('setTableLoading', { type: 'Commission', value: false});
            return res
        })
    },
    async getConversionReportList({ state, dispatch, commit }, data) {
        commit('setTableLoading', { type: 'Conversion', value: true});
        let SelectForm  = data.SelectForm
        let PageSetting = state.ConversionTableSetting.PageSetting

        const response = await this.$axios.post('/api/Report/ConversionList', { SelectForm, PageSetting });
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setConversionReportList', response.data);
            commit('setTableLoading', { type: 'Conversion', value: false});
            return res
        })
    },
    getSelectDate({ commit, dispatch, rootState }, data) {
        let OneDay = 24 * 60 * 60 * 1000
        let date   = new Date();
        let now = date.getTime();
        let stTime = date.getTime()
        let edTime = date.getTime()
        let Week   = date.getDay() == 0 ? 7 : date.getDay()
        let Month  = date.getMonth()
        let Days   = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
        let limitDays = rootState.WebSetting.DATE_LIMIT_RANGE;
        let allowRange = limitDays > 0 ? new Date(now - (limitDays - 1) * OneDay) : null;

        switch (data.value) {
            case "Yesterday":
                stTime -= OneDay
                edTime -= OneDay
                break;
            case "LastWeek":
                stTime -= OneDay * (Week + 6)
                edTime -= OneDay * (Week)
                break;
            case "ThisWeek":
                stTime -= OneDay * (Week - 1)
                edTime -= OneDay * (Week - 7)
                break;
            case "LastMonth":
                Month  = date.getMonth() - 1;
                Days   = new Date(date.getFullYear(), Month + 1, 0).getDate();
                stTime = new Date(date.getFullYear(), Month, 1).getTime()
                edTime = new Date(date.getFullYear(), Month, Days).getTime()
                if (limitDays > 0) {
                    stTime = Math.max(stTime, allowRange.getTime());
                }
                break;
            case "ThisMonth":
                stTime = new Date(date.getFullYear(), Month, 1).getTime()
                edTime = new Date(date.getFullYear(), Month, Days).getTime()
                break;
        }


        let stDate = getDate(stTime) + ' 00:00:00'
        let edDate = getDate(edTime) + ' 23:59:59'

        return { stDate, edDate };
    },
}


function getDate(ms) {
    let date    = new Date(ms);
    let Year    = date.getFullYear();
    let Month   = date.getMonth() + 1;
    let Day     = date.getDate();

    if (Month.toString().length == 1) Month = "0" + Month
    if (Day.toString().length == 1) Day = "0" + Day

    return `${Year}-${Month}-${Day}`
}